import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"


import APPRAISAL_ICON from "../../images/content/services/shutterstock_566863540.jpg"

const AppraisalServices = () => {
  return (
    <>
      <Layout>
        <SEO
          title={`Laboratory Equipment Appraiser & Appraisal Services`}
          description={`Surplus Solutions' certified used equipment appraisers can provide you with their professional expertise whether it is to appraise a single piece of lab equipment or an entire facility. Whether you need to appraise a single piece of surplus equipment or an entire facility, we can help with the entire process.`}
          />

        <div className="row column">
          <div className="row">
            <div className="medium-6 columns ">
              <div>
                <a href="/our-services/appraisal-services">
                  <img src={APPRAISAL_ICON} alt="Appraisal Services" />
                </a>
              </div>
            </div>
            <div className="medium-6 columns">
              <h1>Appraisal Services</h1>
              <hr />
              <p>SSLLC’s certified used equipment appraisers can provide you with their professional expertise whether it is to appraise a single piece of lab equipment or an entire facility.</p>  
            </div>
          </div>
          <hr className="bg-blue mt6 mb8" />
          <p>
            Whether you need to appraise a single piece of surplus equipment or an entire facility, Surplus Solutions’ appraisers can provide you with their professional expertise. At Surplus Solutions, we are AMEA (Association of Machinery and Equipment Appraisers) certified. By requiring AMEA certification, our appraisers receive at least 70 hours of continuing education credits every five years, and Surplus Solutions must have one ‘USPAP compliant’ appraisal reviewed and approved by the AMEA Appraisal Review every two years. This ensures we are up to date with the latest practices and regulations.
          </p>
          <p>
            Surplus Solutions offers different types of appraisals depending on your needs, including:
          </p>
          <p>
            <strong>MARKET VALUE (FAIR MARKET VALUE)</strong>
          </p>
          <p>
            The Market Value appraisal is our most basic report, which includes a professional estimate of the probable price of your surplus asset(s). We recommend a Market Value report when you are not under pressure to unload your surplus asset(s) within a short timeframe.
          </p>
          <p>
            <strong>ORDERLY LIQUIDATION VALUE</strong>
          </p>
          <p>
            An Orderly Liquidation Value appraisal is a similar report to the Market Value report in that it provides a professional estimate of the most probably price of your surplus asset(s), but the findings in this report are customized according to your specific circumstances, most importantly your liquidation timeline.
          </p>
          <p>
            At Surplus Solutions, we work with companies looking to liquidate very quickly, and others who have the ability to plan their liquidations well in advance. Our experience tells us that these many circumstances and expectations can affect the probable price of an asset. This report includes all details that can affect the price of your asset(s).
          </p>
          <p>
            <strong>FORCED LIQUIDATION VALUE (AUCTION)</strong>
          </p>
          <p>
            A Forced Liquidation report provides a professional estimate of the most probable price of your surplus asset(s) when sold at a properly advertised and conducted public auction. We recommend a Forced Liquidation Value report when you must sell your surplus asset within a short timeframe, or when you are looking to swiftly liquidate an entire plant.
          </p>
          <p>
            <strong>NEW REPLACEMENT COST VALUE</strong>
          </p>
          <p>
            With a New Replacement Cost Value report, Surplus Solutions does all the work for you in estimating the cost required to replace an existing asset in the present day market. We examine all manufacturers that make comparable equipment to what you are replacing, as well search our channels for used equipment that may meet your demands.
          </p>
          <p>
            Leave the legwork to Surplus Solutions, whose industry expertise will ensure that you select the best replacement equipment possible.
          </p>
          <p>
            <strong>DESKTOP OPINION</strong>
          </p>
          <p>
            The Desktop Opinion report is not a formal appraisal. Based on limited information provided by you (rather than our own inspection and evaluation), we offer a professional estimate of the probable price on your surplus asset(s). The Desktop Opinion report is a quick report, and is not recommended for use in credit decisions or in legal scenarios. Generally, a Desktop Opinion is used to determine which sales channel would best meet your needs.
          </p>
          <p>
            <br />
            <strong><u>BENEFITS TO USING SURPLUS SOLUTIONS FOR YOUR APPRAISAL NEEDS:</u></strong>
          </p>
          <p>
            <strong><u></u></strong><strong>SAVE TIME.</strong>
          </p>
          <p>
            Collecting all of the data required to provide a truly accurate and detailed report can be extremely time consuming, especially if you don’t have access to a wealth of data and resources. Leave the legwork to our experts, and rest assured that you’ll have a complete and accurate report in no time.
          </p>
          <p>
            <strong>INDUSTRY EXPERTISE.</strong>
          </p>
          <p>
            Our certified appraisers have extraordinary depth of experience evaluating equipment. Many appraisers focus on specific industries, which allows them to learn the specific nuances and current trends within a particular vertical market. Our massive proprietary database provides our certified appraisers with vast historical sales data required for an accurate appraisal.
          </p>
          <p>
            <strong>BEST PRACTICES.</strong>
          </p>
          <p>
            Using professional AMEA accredited appraisers ensures that your reports will be accurate and will meet all industry standards. Each of our reports provides you with legal documentation that stands up in court or at bank.
          </p>
        </div>
      </Layout>
    </>
  )
}

export default AppraisalServices
